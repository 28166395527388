import React from "react";

import { urlFor } from "../../data/client";
import Marquee from "react-fast-marquee";
const OurPartners = ({ data }) => {
  return (
    <div className="w-full h-full p-5 flex flex-col justify-center items-center gap-10">
      <div className="w-full h-44 px-4 md:px-32 flex flex-col justify-center items-center gap-8 md:gap-16">
        <div className="text-center text-[#262626] text-4xl font-semibold break-words">
          Our Members
        </div>
        <div className="overflow-hidden w-full ">
          <Marquee pauseOnHover speed="100">
            {data.map((logo) => (
              <div key={logo._id} className="flex-none w-52 h-20">
                <img
                  className="flex-none w-52 h-16 transition-transform transform hover:scale-110"
                  src={urlFor(logo.logo && logo.logo)}
                  alt="partner 1"
                />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
