import React from "react";
import { urlFor } from "../../data/client";

const Team = ({ data }) => {
  return (
    <div className="w-full h-full pt-16 pb-24 px-8 md:px-32 bg-[#050614] flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-8 w-full">
        <div className="text-center text-white text-3xl md:text-5xl font-semibold leading-tight">
          S.R.B.A Board Members
        </div>
        <div className="flex flex-wrap justify-center items-center gap-8 w-full h-full">
          {data.map((member, index) => {
            const imageUrl = member.image ? urlFor(member.image) : null;

            return (
              <div
                key={index}
                className="group flex flex-col justify-center items-center gap-5 p-5 w-full sm:w-[calc(30.33%-1rem)] md:w-[calc(30.33%-1rem)] lg:w-[calc(30.33%-1rem)] transition-transform transform hover:scale-105"
              >
                <div className="flex flex-col justify-center items-center gap-1 w-full mb-1">
                  <div className="text-[rgba(255,255,255,0.55)] text-4xl font-bold group-hover:text-white transition-colors">
                    {member.name}
                  </div>
                  <div className="text-[rgba(218,210,210,0.80)] text-lg font-normal group-hover:text-white transition-colors">
                    {member.description}
                  </div>
                </div>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={`story ${index + 1}`}
                    className="w-[250px] h-[250px] mt-2"
                  />
                ) : (
                  <div className="w-[250px] h-[250px] flex justify-center items-center bg-gray-200 text-gray-500 mt-2">
                    No Image
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Team;
