import React from "react";
import MBanner from "../../components/Banner/MBanner";
import OurHappyClients from "../../components/OurClient/OurClient";
const Community = ({ communityBanner, teams }) => {
  return (
    <div>
      <MBanner data={communityBanner} />

      <div className="flex items-center justify-center min-h-screen text-center flex-col">
        <div className="text-black text-5xl font-semibold font-['Poppins']">
          Welcome To Our Community
        </div>
        <div className="w-[1184px] text-[#302f2f]/70 text-[28.80px] font-semibold font-['Open Sans'] leading-[46.08px] mt-6">
          Welcome to the Story Rd. Business Association, where we are committed
          to fostering economic vitality and community well-being. Our
          association is dedicated to enhancing the Story Rd. area through
          strategic economic development, maintaining district safety,
          cleanliness, and beautification. As a member, you join a vibrant
          community of business owners and public sector partners who work
          together to promote cultural presence and environmental
          responsibility. We believe in the power of collaboration to drive
          positive change and look forward to working with you to make our
          district a thriving hub for businesses and residents alike. Stay
          connected with us for updates on initiatives, events, and
          opportunities to get involved. Together, we can build a stronger, more
          prosperous community.
        </div>
      </div>

      <OurHappyClients
        data={teams}
        tital="Team Member"
        csize=" h-[450px]"
        bsize="w-[250px] h-[450px]"
        isize="w-[250px] h-[400px]"
      />
    </div>
  );
};

export default Community;
