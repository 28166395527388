import React from "react";
import { urlFor } from "../../data/client";

const UpcomingEvents = ({ data }) => {
  // Get the latest 2 events
  const latestEvents = data.slice(0, 2);

  return (
    <div className="w-full h-full py-12 flex flex-col justify-center items-center gap-14">
      <div className="w-full text-center text-[#353535] text-4xl font-semibold break-words font-poppins">
        Upcoming Events
      </div>
      <div className="w-full h-auto flex flex-col gap-8">
        {latestEvents.map((event, index) => (
          <div
            key={index}
            className={`w-full bg-[#FBFBFB] flex flex-col md:flex-row justify-center items-center gap-8 md:gap-24 p-4 md:p-8 ${
              index % 2 === 1 ? "flex-col-reverse md:flex-row-reverse" : ""
            }`}
          >
            <div className="bg-white flex justify-end items-center w-full md:w-auto">
              <img
                className="w-full md:w-[705px] h-auto md:h-[526px] object-cover rounded-lg"
                src={urlFor(event.image && event.image[0])}
                alt="event"
              />
            </div>
            <div className="w-full md:w-[506px] flex flex-col justify-start items-center gap-6">
              <div className="w-full h-auto flex flex-col justify-center items-start gap-2">
                <div className="relative w-full h-auto flex flex-col md:flex-row justify-around items-center md:items-start gap-4 md:gap-0">
                  {event.date.split("|").map((date, i) => (
                    <div
                      key={i}
                      className="text-center md:text-left text-[#35353599] text-xl font-medium font-poppins leading-[27px] break-words"
                    >
                      {date}
                    </div>
                  ))}
                </div>
                <div className="w-full flex flex-col md:flex-row justify-start items-center gap-4 md:gap-6">
                  <div className="text-center md:text-left text-[#35353599] text-xl font-light font-poppins leading-[27px] break-words">
                    {event.time.join(" | ")}
                  </div>
                  <div className="text-center md:text-left text-[#353535cc] text-xl font-medium font-poppins leading-[27px] break-words">
                    {event.location}
                  </div>
                </div>
              </div>
              <div className="w-full h-auto flex flex-col justify-start items-start gap-6">
                <div className="w-full h-auto flex flex-col justify-start items-start gap-5">
                  <div className="w-full text-[#262626] text-2xl font-bold font-poppins leading-[45px] break-words">
                    {event.title}
                  </div>
                  <div className="w-full text-[#1E1E1E] text-lg font-light font-poppins leading-[28px] break-words">
                    {event.description}
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-start items-center gap-6 md:gap-24">
                  {event.Etype === "Free" ? (
                    <a href={`/event-detail/${event._id}`}>
                      <div className="w-[281px] h-[60px] px-4 py-4 border border-black flex justify-center items-center gap-2 bg-black hover:w-[300px] hover:h-[70px] transition-all duration-300">
                        <div className="text-[#ffffff] text-lg font-semibold font-poppins leading-[28.80px] break-words">
                          Read More
                        </div>
                      </div>
                    </a>
                  ) : (
                    <div className="w-[281px] h-[60px] px-4 py-4 border border-black flex justify-center items-center gap-2 bg-black hover:w-[300px] hover:h-[70px] transition-all duration-300">
                      <div className="text-[#ffffff] text-lg font-semibold font-poppins leading-[28.80px] break-words">
                        Buy Tickets
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <a href="/events">
        <div className="w-[281px] h-[60px] px-4 py-4 border border-black flex justify-center items-center gap-2 bg-black hover:w-[300px] hover:h-[70px] transition-all duration-300">
          <div className="text-[#ffffff] text-lg font-semibold font-poppins leading-[28.80px] break-words">
            See More
          </div>
        </div>
      </a>
    </div>
  );
};

export default UpcomingEvents;
