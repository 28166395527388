import { useState, useEffect } from "react";
import React from "react";
import Navbar from "../components/Nav/Nav";
import Home from "../container/Home/Home";
import Email from "../components/Email/Email";
import EventDetail from "../container/EventDetail/EventDetail";
import { client } from "../data/client";
import {
  bannerquery,
  logoteamquery,
  projectquery,
  membershipquery,
  eventquery,
  communityquery,
  teamsquery,
} from "../data/data";

import Footer from "../components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./AboutUs/AboutUs";
import Event from "./Event/Event";
import Community from "./Community/Community";
// import Membership from "./Membership/Membership";

// import News from "./News/News";

const App = () => {
  const [banner, setBanner] = useState([]);
  const [aboutBanner, setaboutBanner] = useState([]);
  const [memberBanner, setmemberBanner] = useState([]);
  const [eventBanner, seteventBanner] = useState([]);
  const [communityBanner, setcommunityBanner] = useState([]);
  const [newsBanner, setnewsBanner] = useState([]);

  const [logoteam, setLogoteam] = useState([]);
  const [project, setProject] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [sponsorData, setSponsorData] = useState([]);
  const [event, setevent] = useState([]);
  const [community, setCommunity] = useState([]);

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerData = await bannerquery();
        client.fetch(bannerData).then((data) => {
          setBanner(
            data.filter(
              (item) => item.category === "Home" || item.category === "All"
            )
          );

          setaboutBanner(
            data.filter(
              (item) => item.category === "About" || item.category === "All"
            )
          );

          setmemberBanner(
            data.filter(
              (item) => item.category === "Member" || item.category === "All"
            )
          );
          seteventBanner(
            data.filter(
              (item) => item.category === "Events" || item.category === "All"
            )
          );
          setcommunityBanner(
            data.filter(
              (item) => item.category === "Community" || item.category === "All"
            )
          );
          setnewsBanner(
            data.filter(
              (item) => item.category === "News" || item.category === "All"
            )
          );
        });

        const logoteamData = await logoteamquery();
        client.fetch(logoteamData).then((data) => {
          setLogoteam(data);
        });

        const communityData = await communityquery();
        client.fetch(communityData).then((data) => {
          setCommunity(data);
        });

        const eventData = await eventquery();
        const eventResults = await client.fetch(eventData);

        // Remove duplicates based on _id
        const uniqueEvents = eventResults.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t._id === value._id)
        );

        setevent(uniqueEvents);

        const teamsData = await teamsquery();
        client.fetch(teamsData).then((data) => {
          setTeams(data);
        });

        const projectData = await projectquery();
        client.fetch(projectData).then((data) => {
          setProject(data);
        });

        const membershipData = await membershipquery();
        client.fetch(membershipData).then((data) => {
          setMembershipData(
            data.filter((item) => item.type === "Membership and Packages")
          );
          setSponsorData(
            data.filter((item) => item.type === "Sponsor Packages")
          );
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              banner={banner}
              logoteam={logoteam}
              project={project}
              membershipData={membershipData}
              sponsorData={sponsorData}
              event={event}
              community={community}
            />
          }
        />
        <Route
          path="team-2024-user-srbaemail-subscription"
          element={<Email />}
        />

        <Route
          path="about"
          element={
            <AboutUs
              aboutBanner={aboutBanner}
              project={project}
              teams={teams}
            />
          }
        />
        <Route
          path="event-detail/:id"
          element={<EventDetail events={event} />}
        ></Route>

        <Route
          path="events"
          element={<Event eventBanner={eventBanner} event={event} />}
        />
        <Route
          path="community"
          element={
            <Community communityBanner={communityBanner} teams={teams} />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
