import React from "react";
import { FaBullseye, FaLightbulb, FaMedal, FaTrophy } from "react-icons/fa";
import who from "./who.jpg";
import when from "./When.jpg";
import what from "./what.jpg";

const Purpose = () => {
  const sections = [
    {
      title: "Our Mission",
      description:
        "To drive economic vitality and district, cleanliness, and beautification in the Story Rd. geographical area in the City of San Jose. With a strong cultural presence, S.R.B.A will help market the corridor for economic development activities, safety, and promote environmental responsibilities for the area's businesses.",
      icon: <FaBullseye className="w-12 h-12 text-orange-500" />,
    },
    {
      title: "Our Value",
      description:
        "The organization is built on principles of integrity, collaboration, and sustainability, upholding ethical business practices and embracing diversity and inclusivity as drivers of innovation and prosperity.",

      icon: <FaMedal className="w-12 h-12 text-green-500" />,
    },
    {
      title: "Our Vision",
      description:
        "Together, we envision Story Road as a thriving hub of commerce, culture, and community, where businesses thrive, residents flourish, and visitors feel welcome. We are dedicated to preserving the historical heritage and natural beauty of Story Road, ensuring its long-term vitality and attractiveness.",
      icon: <FaLightbulb className="w-12 h-12 text-yellow-500" />,
    },
    {
      title: "Our Commitment",
      description:
        "SRBA promotes the unique character, culture, and diversity of the Story Road community, and strives to create a safe, clean, and inviting environment for residents and visitors.",
      icon: <FaTrophy className="w-12 h-12 text-blue-500" />,
    },
  ];

  return (
    <div>
      <div className="w-full h-full pt-16 pb-24 px-8 md:px-32 bg-[#050614] flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center gap-8 w-full">
          <div className="text-center text-white text-3xl md:text-5xl font-semibold leading-tight"></div>

          <div className="flex flex-col justify-center items-center gap-12 w-full h-full">
            <div className="flex flex-col md:flex-row justify-center items-center gap-8 w-full">
              <div className="group flex flex-col justify-center items-center gap-5 p-5 w-full md:w-[374px] h-[546px] transition-transform transform hover:scale-105">
                <div className="flex flex-col justify-start items-start gap-1 w-full h-[172px]">
                  <div className="text-[rgba(255,255,255,0.55)] text-4xl font-bold group-hover:text-white transition-colors">
                    When
                  </div>
                  <div className="text-[rgba(218,210,210,0.80)] text-lg font-normal group-hover:text-white transition-colors">
                    The Story Road Business Association (SRBA) meets monthly on
                    the second Thursday of the month at noon. &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </div>
                </div>
                <img
                  src={when}
                  alt="story 1"
                  className="w-full h-[314px] mt-16"
                />
              </div>
              <div className="group flex flex-col justify-center items-center gap-5 p-5 w-full md:w-[374px] h-[546px] transition-transform transform hover:scale-105">
                <div className="flex flex-col justify-start items-start gap-1 w-full h-[172px]">
                  <div className="text-[rgba(255,255,255,0.55)] text-4xl font-bold group-hover:text-white transition-colors">
                    Who
                  </div>
                  <div className="text-[rgba(218,210,210,0.80)] text-lg font-normal group-hover:text-white transition-colors">
                    Members and stakeholders of local businesses, residents, and
                    stakeholders along Story Road in San Jose, California. SRBA
                    partners with the Office of Economic Development to enhance
                    its efforts.
                  </div>
                </div>
                <img
                  src={who}
                  alt="story 2"
                  className="w-full h-[314px] mt-16"
                />
              </div>
              <div className="group flex flex-col justify-center items-center gap-5 p-5 w-full md:w-[374px] h-[546px] transition-transform transform hover:scale-105">
                <div className="flex flex-col justify-start items-start gap-1 w-full h-[172px]">
                  <div className="text-[rgba(255,255,255,0.55)] text-4xl font-bold group-hover:text-white transition-colors">
                    What
                  </div>
                  <div className="text-[rgba(218,210,210,0.80)] text-lg font-normal group-hover:text-white transition-colors">
                    To drive economic vitality and district safety, cleanliness,
                    and beautification in the Story Rd. geographical area in the
                    City of San Jose, CA.
                  </div>
                </div>
                <img
                  src={what}
                  alt="story 3"
                  className="w-full h-[314px] mt-16"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-6 px-4 pt-24 pb-10">
        {sections.map((section, index) => (
          <div
            key={index}
            className="w-full md:w-[calc(50%-1rem)] lg:w-[calc(50%-1rem)] xl:w-[calc(50%-1rem)] bg-white border rounded-lg p-8 flex flex-col justify-center items-center transition transform hover:scale-105 hover:shadow-lg hover:bg-gray-100"
          >
            <div className="w-24 h-24 mb-4 flex justify-center items-center">
              {section.icon}
            </div>
            <div className="text-center text-black text-2xl lg:text-3xl font-semibold mb-4">
              {section.title}
            </div>
            <div className="text-center text-neutral-600 text-base lg:text-lg">
              {section.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Purpose;
