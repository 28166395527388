import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { eventdetailsquery } from "../../data/data"; //
import { FaArrowLeft } from "react-icons/fa";
import { urlFor, client } from "../../data/client";
import Marquee from "react-fast-marquee";

const EventDetail = ({ events }) => {
  const { id } = useParams();
  const [event, setEvent] = useState([]);

  useEffect(() => {
    const getEventDetails = async () => {
      try {
        const eventdata = await eventdetailsquery(id);
        client.fetch(eventdata).then((data) => {
          setEvent(data); // Assuming the query returns an array with one item
        });
      } catch (err) {
        console.log(err);
      }
    };
    getEventDetails();
  }, [id]);

  const firstImageUrl =
    event.image && event.image[0] ? urlFor(event.image[0]).url() : "";
  const relatedEvents = events.filter((item) => item._id !== id);
  return (
    <div className="py-16">
      <div className="h-11 flex justify-start items-center gap-2.5 mt-12 ml-8">
        <div className="text-black/50 text-xl font-medium font-['Poppins']">
          <a
            href="/events"
            className="flex items-center gap-2 hover:text-blue-900  transition duration-300"
          >
            <FaArrowLeft />
            Event / Event Detail
          </a>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div className="w-full max-w-[900px] aspect-w-2 aspect-h-1">
          <img
            className=" object-cover w-full h-full"
            src={firstImageUrl}
            alt="Event Detail"
          />
        </div>
      </div>
      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="w-full max-w-[1042px] flex flex-col justify-start items-start gap-10">
          <div className="w-full flex flex-col md:flex-row justify-start items-center gap-4 md:gap-[258px]">
            <div className="w-full md:w-[606px] flex flex-col justify-start items-start gap-4 md:gap-[13px]">
              <div className="w-full md:w-[451.35px] h-9 relative">
                <div className="absolute top-0 left-0 text-[#353535]/60 text-xl md:text-2xl font-medium font-['Poppins'] leading-9">
                  {event.date}
                </div>

                <div className="absolute left-[161.77px] top-[7px] origin-top-left rotate-90 border-2 border-[#353535]/60"></div>
                <div className="absolute left-[311.45px] top-[7px] origin-top-left rotate-90 border-2 border-[#353535]/60"></div>
              </div>
              <div className="w-full flex flex-col md:flex-row justify-start items-center gap-4 md:gap-[33px]">
                <div className="text-[#353535]/60 text-xl md:text-2xl font-normal font-['Poppins'] leading-9">
                  {event.time}
                </div>
                <div className="text-[#353535]/80 text-2xl md:text-[32px] font-medium font-['Poppins'] leading-[48px]">
                  {event.location}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-start gap-10">
            <div className="w-full flex flex-col justify-start items-start gap-6">
              <div className="text-neutral-800 text-3xl md:text-5xl font-bold font-['Poppins'] leading-[48px] md:leading-[72px]">
                {event.title}
              </div>
              <div className="opacity-70 text-[#1e1e1e] text-base md:text-xl font-normal font-['Poppins'] leading-6 md:leading-7">
                {event.description}
              </div>
            </div>
            <div className="flex justify-start items-center gap-4 md:gap-[57px]">
              <div className="w-full md:w-[212px] h-[60px] px-2.5 py-[15px] bg-black flex justify-center items-center gap-2.5">
                <div className="text-white text-lg font-semibold font-['Poppins'] underline leading-[28.80px]">
                  <a href="/team-2024-user-srbaemail-subscription">
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bgfirst py-8"
        style={{
          background: "linear-gradient(to bottom, white 170px, black 150px)",
        }}
      >
        <div className="container mx-auto">
          <h2 className="text-center text-3xl font-semibold mb-8">
            Events Gallery
          </h2>
          <div className="flex flex-col items-center space-y-4">
            <div className="flex space-x-4">
              <div className="image">
                <img
                  className="w-[400px] h-[200px] object-cover"
                  src={
                    event.image && event.image[1]
                      ? urlFor(event.image[1]).url()
                      : ""
                  }
                  alt=""
                />
              </div>
              <div className="image">
                <img
                  className="w-[400px] h-[200px] object-cover"
                  src={
                    event.image && event.image[2]
                      ? urlFor(event.image[2]).url()
                      : ""
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="image">
              <img
                className="w-[820px] h-[410px] object-cover"
                src={
                  event.image && event.image[3]
                    ? urlFor(event.image[3]).url()
                    : ""
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-30">
        <h2 className="text-center mt-12 mb-12 text-[#324d67] text-3xl font-semibold ">
          Related Events
        </h2>

        <div className="overflow-hidden w-full h-72">
          <Marquee pauseOnHover speed="100">
            {relatedEvents.length > 0 ? (
              relatedEvents.map((item) => (
                <div
                  key={item._id}
                  className="flex-none w-[410px] h-[210px]  mt-4  rounded-lg shadow-lg transform-3d testimonial-box transition-transform transform hover:scale-120 m-1"
                >
                  <img
                    className="w-[400px] h-[200px] object-cover"
                    src={
                      item.image && item.image[0]
                        ? urlFor(item.image[0]).url()
                        : ""
                    }
                    alt={item.title}
                  />
                </div>
              ))
            ) : (
              <div className="text-center w-full text-xl font-semibold text-gray-500">
                Other events are not available right now.
              </div>
            )}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
