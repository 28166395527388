import React from "react";
import Marquee from "react-fast-marquee";
import "./style.css";
import { urlFor } from "../../data/client";

const OurHappyClients = ({ data, tital, csize, bsize, isize }) => {
  const getImageUrl = (image) => {
    if (
      image._type === "image" &&
      image._upload &&
      image._upload.previewImage
    ) {
      return image._upload.previewImage; // Handle base64 image preview
    } else {
      return urlFor(image); // Handle other image URLs
    }
  };

  return (
    <div className="w-full h-full p-5 flex flex-col pt-32 pb-40 justify-center items-center gap-10">
      <div className="w-full h-['30rem'] px-4 md:px-32 flex flex-col justify-center items-center gap-8 md:gap-16">
        <div className="text-center text-[#262626] text-3xl font-semibold break-words">
          {tital}
        </div>
        <div className={`overflow-hidden w-full ${csize}`}>
          <Marquee pauseOnHover speed="100">
            {data.map((item) => (
              <div
                className={`flex-none  ${bsize}  mt-4  rounded-lg shadow-lg transform-3d testimonial-box transition-transform transform hover:scale-120 m-1`}
                style={{ backgroundColor: "white" }}
                key={item._id}
              >
                <img
                  src={getImageUrl(item.image)}
                  alt="ClientImage"
                  className={isize}
                />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default OurHappyClients;
