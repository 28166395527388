import React from "react";

const MAboutUs = () => {
  return (
    <div className="w-full h-full pt-16 pb-24 px-8 md:px-32 bg-[#050614] flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-8 w-full">
        <div className="text-center text-white text-3xl md:text-5xl font-semibold leading-tight">
          About Us
        </div>

        <div className="text-center text-[rgba(218,210,210,0.80)] text-lg font-normal">
          The Story Road Business Association (SRBA) is committed to supporting
          the local business community along Story Road in San Jose, California.
          SRBA offers a variety of services aimed at promoting economic growth
          and fostering a collaborative business environment. These services
          include networking opportunities, business promotion and marketing,
          advocacy and representation, educational workshops and seminars, and
          access to valuable industry information. By partnering with the Office
          of Economic Development, SRBA enhances its efforts to create a
          thriving business corridor. The association's initiatives help drive
          the success and growth of businesses along Story Road, ensuring a
          vibrant and prosperous local economy. SRBA meets monthly on the 2nd
          Thurs of the month at noon. Please contact our secretary, Thanh Nguyen
          at info@srba.co for additional information.
        </div>

        <a href="/about">
          <div className="flex justify-center items-center w-[281px] h-[60px] bg-[#676666] border border-gray-500 p-4 text-white text-lg font-semibold">
            Learn More
          </div>
        </a>
      </div>
    </div>
  );
};

export default MAboutUs;
